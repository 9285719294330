<template>
  <div>
    <b-collapse :open="!!announcement" v-if="!sent">
      <template #trigger>
        <b-button
          v-if="!announcement"
          label="Add Announcement"
          icon-left="plus"
          type="is-info" />
      </template>
      <div class="notification">
        <div>
          <h4 class="title is-4">{{ announcement ? 'Update Announcement' : 'New Announcement details' }}</h4>

          <b-notification
            v-if="errors.length"
            type="is-danger"
            aria-close-label="Close"
            role="alert"
          >
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </b-notification>

          <form @submit="handleSubmit">
            <b-field label="Title">
              <b-input v-model="formData.title"></b-input>
            </b-field>

            <b-field label="Full Text">
              <b-input type="textarea" v-model="formData.text"></b-input>
            </b-field>

            <div class="columns">
              <div class="column is-4">
                <b-field label="Active?">
                  <b-switch
                    true-value="1"
                    v-model="formData.active"
                    passive-type="is-danger"
                    type="is-success">
                    {{ formData.active ? 'Active' : 'Inactive'}}
                  </b-switch>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="Display Duration">
                  <b-input v-model="formData.display_duration"></b-input>
                </b-field>
              </div>
            </div>

            <b-button type="is-success" native-type="submit">Submit</b-button>
          </form>

          <b-loading :is-full-page="false" v-model="loadingForm"></b-loading>
        </div>
      </div>
    </b-collapse>
    <div v-else class="notification has-text-centered is-success">
      <h3 class="title is-3">Awesome!</h3>
      <p>Announcement is added!</p>
    </div>
  </div>
</template>

<script>
import { Announcement } from '@/services/api';

export default {
  name: 'announcement-form',
  props: {
    announcement: Object,
  },
  watch: {
    announcement(newAnnouncement) {
      if (newAnnouncement) {
        this.formData = newAnnouncement;
      }
    },
  },
  data() {
    return {
      errors: [],
      formData: {
        active: false,
        display_duration: 300,
        title: null,
        text: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  mounted() {
    if (this.announcement) {
      this.formData = this.announcement;
    }
  },
  methods: {
    createAnnouncement() {
      Announcement.createAnnouncement(this.formData)
        .then(() => {
          this.sent = true;
          this.$store.dispatch('announcement/loadAnnouncements', 1);
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    updateAnnouncement() {
      Announcement.updateAnnouncement(this.announcement.id, this.formData)
        .then(() => {
          this.sent = true;
          this.$router.push({ path: '/announcements' });
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      if (this.announcement) {
        this.updateAnnouncement();
      } else {
        this.createAnnouncement();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
