<template>
  <div class="container">
    <b-loading :is-full-page="false" v-model="loadingAnnouncement"></b-loading>

    <div v-if="announcement">
      <announcement-form :announcement="announcement"></announcement-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AnnouncementForm from '@/components/Announcement/AnnouncementForm.vue';

export default {
  name: 'AnnouncementDetails',
  components: { AnnouncementForm },
  props: {
    announcementId: String,
  },
  computed: {
    ...mapState('announcement', ['loadingAnnouncement', 'announcement']),
  },
  mounted() {
    this.loadAnnouncement(this.announcementId);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadAnnouncement(to.params.announcementId);
    next();
  },
  methods: {
    loadAnnouncement(id) {
      this.$store.dispatch('announcement/loadAnnouncement', id);
    },
  },
};
</script>
